body {
  margin: 0;
  font-family: 'Open Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navigation-link {
  color: white !important;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
}

.bg-light-gray {
  background-color: #1c1c1c;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  box-shadow: 0 6px 9px 0 rgba(0,0,0,0.6)
}

.space-up-down {
  margin-top: 15px;
  margin-bottom: 15px;
}

nav {
  padding-left: 10% !important;
  padding-right: 10% !important;
  font-size: 12px;
}

.animation {
  transition: all .5s ease-in-out;
}

.fixed-height {
  height: 60px;
}
